import GradientButton from "../gradientButton/GradientButton";
import "./uamSection.scss";

const UAMSection = () => {
  return (
    <>
      <div className="uam-section">
        <h2>Uniform Accounting & Reporting Manual</h2>
        <p>
          The Uniform Accounting & Reporting Manual standardizes financial
          reporting practices for Idaho's local governmental entities, offering
          clear guidelines that ensure financial transparency for citizens and
          decision-makers.{" "}
        </p>
        <GradientButton
          buttonSize="button-text-medium"
          buttonText="View Manual"
          ButtonAction={() =>
            window.open(
              "https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/pdfs/Interim+UAM_Final+PDF+Version.pdf"
            )
          }
        />
      </div>
    </>
  );
};

export default UAMSection;
