export const VENDORS_URLS = {
  TOP_VENDOR_TOTAL_SPENT: "",
  TOP_VENDOR_MOST_USED: "",
  VENDOR_TRANSACTION: "",
};

export const STATE_URLS = {
  BUDGET: "",
  BUDGET_TO_ACTUAL_EXPENDITURE: "",
  BUDGET_TO_ACTUAL_REVENUE: "",
  EMPLOYEE_PAY_RATES: "https://stories.opengov.com/idaho/published/9XkbwanAy",
  TOP_VENDOR_TOTAL_SPENT: "",
  TOP_VENDOR_MOST_USED: "",
  COVID_REPORT: "https://stories.opengov.com/idahorebounds/published/0_noknT6w",
  TRANSACTION:
    "https://idaho.opengov.com/transparency-reporting/idaho/2b17eed6-3282-4416-ab38-656795512745/da153265-42ce-4f81-ac3e-31da6f90766a?savedViewId=87e710d0-1c55-4548-8341-396868936d02",
  TRAVEL: "",
  EMPLOYER_PAYROLL_COST:
    "https://idaho.opengov.com/transparency-reporting/idaho/2b20b136-297e-48fd-b620-63a6ffa100b3/969fe63b-3f27-48dd-b447-f028e4f5e91b?savedViewId=d06d76d3-c926-4465-b814-bc3598d98f66",
  GEN_FUND_REV_AND_EXP: "",
  EMPLOYEE_COUNT:
    "https://idaho.opengov.com/transparency-reporting/idaho/96f80223-1a8d-4105-aa51-b57f19fe86aa/1b937b73-93a6-43f5-82b2-96cb88051ec0?savedViewId=7cb3d703-3f96-49c9-b91c-04e270260a7b",
  WORKFORCE_TENURE:
    "https://idaho.opengov.com/transparency-reporting/idaho/6edcb574-8d1c-45a6-8289-ee8279a24abf/922c5bd6-f618-4650-be0a-175d97e138bf?savedViewId=197c8282-a28a-4d2a-97ba-f99109011345",
};

export const EXTERNAL_URLS = {
  PAY_RATES:
    "https://idaho.opengov.com/transparency-reporting/idaho/802fa944-b831-4e4d-a8ed-440b44f54bc9/27b100e3-97d9-483d-b6be-401af1edd93e?savedViewId=22c71bd8-812a-4c4b-bcd0-66140da71a40",
  EMPLOYER_PAYROLL_COST:
    "https://idaho.opengov.com/transparency-reporting/idaho/802fa944-b831-4e4d-a8ed-440b44f54bc9/46f11b46-7029-41f1-ac2e-a8e9fa06e828?savedViewId=dac4b0a9-d9d8-4a06-978e-a573239c5a16",
};
