import { FC, memo } from "react";

const TwitterIcon: FC<React.SVGProps<SVGSVGElement>> = memo(() => {
  return (
    <svg
      width="30"
      height="22"
      viewBox="0 -1 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6833 0H18.7368L12.0656 7.6255L19.9143 18H13.7691L8.95644 11.7071L3.44849 18H0.393307L7.52925 9.84345L0 0.0008298H6.3012L10.6516 5.75273L15.6833 0ZM14.6121 16.1729H16.304L5.38183 1.73171H3.56631L14.6121 16.1729Z"
        fill="#CED0D1"
      />
    </svg>
  );
});

export default TwitterIcon;
