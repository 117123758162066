import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BasicEntity } from "../../types/interfaces/entityInterfaces";
import {
  HOME_PAGE_CONSTANTS,
  URL_CONSTANTS,
  DESKTOP_SCREEN_MIN_WIDTH,
} from "../../types/constants";
import {
  CITY_QUICK_LINK_CARDS,
  COUNTY_QUICK_LINK_CARDS,
  EDUCATION_QUICK_LINK_CARDS,
  LOCAL_DISTRICT_QUICK_LINK_CARDS,
  MEGA_SEARCH_MENU_CONSTANTS,
  STATE_QUICK_LINK_CARDS,
  STATE_SIMPLE_LINKS,
  WORKFORCE_SALARY_QUICK_LINK_CARDS,
} from "../../types/constants/megaSearchMenuConstants";
import Logo from "../../styles/images/logoTransparent.webp";
import {
  BreadCrumbs,
  HamburgerMenu,
  MegaMenuLink,
  MegaSearchMenu,
} from "./headerComponents";
import Navbar from "../navbar/Navbar";
import { getCounties } from "../countiesLandingPage/countiesLandingPage.service";
import "./header.scss";

const Header = () => {
  const [currentScreenSize, setCurrentScreenSize] = useState<number>(
    window.innerWidth
  );
  const [counties, setCounties] = useState<BasicEntity[]>([]);
  const [showMegaSearch, setShowMegaSearch] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState<string>("");

  const location = useLocation();
  const pathName = location.pathname;
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    getFilterData();
    window.addEventListener("resize", () =>
      setCurrentScreenSize(window.innerWidth)
    );
    setShowMegaSearch(() => false);
    return () => {
      window.removeEventListener("resize", () =>
        setCurrentScreenSize(window.innerWidth)
      );
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowMegaSearch(() => false);
    }, 50);
  }, [pathName]);

  const getFilterData = async () => {
    const filterData = await getCounties();
    setCounties(filterData);
  };
  const handleGetUrlParam = () => {
    if (pathName.toLowerCase().includes("/counties/")) {
      return counties.find((county) => county.id === Number(id))?.name;
    } else if (pathName.toLowerCase().includes("/stateagencies/")) {
      return id;
    }
  };

  return (
    <>
      <Navbar />
      <div className="header">
        <img
          onClick={() => (window.location.href = "/")}
          src={Logo}
          className="logo-image"
          alt="Transparent Idaho Logo"
        />
        {currentScreenSize < DESKTOP_SCREEN_MIN_WIDTH ? (
          <HamburgerMenu />
        ) : (
          <div>
            <div className="navigation-container">
              <MegaMenuLink
                linkTo={URL_CONSTANTS.WORKFORCE_SALARY}
                linkTitle={HOME_PAGE_CONSTANTS.headerLabels.WORKFORCE_SALARY}
                setShowMegaSearch={setShowMegaSearch}
                setActiveMenu={setActiveMenu}
                isActiveMenu={
                  showMegaSearch &&
                  activeMenu === URL_CONSTANTS.WORKFORCE_SALARY
                }
              >
                <MegaSearchMenu
                  title={MEGA_SEARCH_MENU_CONSTANTS.workforceSalary.TITLE}
                  showMegaSearchMenu={
                    showMegaSearch &&
                    activeMenu === URL_CONSTANTS.WORKFORCE_SALARY
                  }
                  quickLinkCards={WORKFORCE_SALARY_QUICK_LINK_CARDS}
                  setShowMegaSearchMenu={setShowMegaSearch}
                />
              </MegaMenuLink>

              <MegaMenuLink
                linkTo={URL_CONSTANTS.STATE}
                linkTitle={HOME_PAGE_CONSTANTS.headerLabels.STATE}
                setShowMegaSearch={setShowMegaSearch}
                setActiveMenu={setActiveMenu}
                isActiveMenu={
                  showMegaSearch && activeMenu === URL_CONSTANTS.STATE
                }
              >
                <MegaSearchMenu
                  title={MEGA_SEARCH_MENU_CONSTANTS.state.TITLE}
                  showMegaSearchMenu={
                    showMegaSearch && activeMenu === URL_CONSTANTS.STATE
                  }
                  quickLinkCards={STATE_QUICK_LINK_CARDS}
                  setShowMegaSearchMenu={setShowMegaSearch}
                  simpleLinks={STATE_SIMPLE_LINKS}
                />
              </MegaMenuLink>

              <MegaMenuLink
                linkTo={URL_CONSTANTS.COUNTY}
                linkTitle={HOME_PAGE_CONSTANTS.headerLabels.COUNTY}
                setShowMegaSearch={setShowMegaSearch}
                setActiveMenu={setActiveMenu}
                isActiveMenu={
                  showMegaSearch && activeMenu === URL_CONSTANTS.COUNTY
                }
              >
                <MegaSearchMenu
                  title={MEGA_SEARCH_MENU_CONSTANTS.county.TITLE}
                  showMegaSearchMenu={
                    showMegaSearch && activeMenu === URL_CONSTANTS.COUNTY
                  }
                  listData={counties}
                  quickLinkCards={COUNTY_QUICK_LINK_CARDS}
                  setShowMegaSearchMenu={setShowMegaSearch}
                />
              </MegaMenuLink>

              <MegaMenuLink
                linkTo={URL_CONSTANTS.CITY}
                linkTitle={HOME_PAGE_CONSTANTS.headerLabels.CITY}
                setShowMegaSearch={setShowMegaSearch}
                setActiveMenu={setActiveMenu}
                isActiveMenu={
                  showMegaSearch && activeMenu === URL_CONSTANTS.CITY
                }
              >
                <MegaSearchMenu
                  title={MEGA_SEARCH_MENU_CONSTANTS.city.TITLE}
                  showMegaSearchMenu={
                    showMegaSearch && activeMenu === URL_CONSTANTS.CITY
                  }
                  quickLinkCards={CITY_QUICK_LINK_CARDS}
                  setShowMegaSearchMenu={setShowMegaSearch}
                />
              </MegaMenuLink>

              <MegaMenuLink
                linkTo={URL_CONSTANTS.LOCAL_DISTRICT}
                linkTitle={HOME_PAGE_CONSTANTS.headerLabels.LOCAL_DISTRICT}
                setShowMegaSearch={setShowMegaSearch}
                setActiveMenu={setActiveMenu}
                isActiveMenu={
                  showMegaSearch && activeMenu === URL_CONSTANTS.LOCAL_DISTRICT
                }
              >
                <MegaSearchMenu
                  title={MEGA_SEARCH_MENU_CONSTANTS.localDistricts.TITLE}
                  showMegaSearchMenu={
                    showMegaSearch &&
                    activeMenu === URL_CONSTANTS.LOCAL_DISTRICT
                  }
                  quickLinkCards={LOCAL_DISTRICT_QUICK_LINK_CARDS}
                  setShowMegaSearchMenu={setShowMegaSearch}
                />
              </MegaMenuLink>

              <MegaMenuLink
                linkTo={URL_CONSTANTS.EDUCATION}
                linkTitle={HOME_PAGE_CONSTANTS.headerLabels.EDUCATION}
                setShowMegaSearch={setShowMegaSearch}
                setActiveMenu={setActiveMenu}
                isActiveMenu={
                  showMegaSearch && activeMenu === URL_CONSTANTS.EDUCATION
                }
              >
                <MegaSearchMenu
                  title={MEGA_SEARCH_MENU_CONSTANTS.education.TITLE}
                  showMegaSearchMenu={
                    showMegaSearch && activeMenu === URL_CONSTANTS.EDUCATION
                  }
                  quickLinkCards={EDUCATION_QUICK_LINK_CARDS}
                  setShowMegaSearchMenu={setShowMegaSearch}
                />
              </MegaMenuLink>
            </div>
          </div>
        )}
      </div>
      <BreadCrumbs urlParam={handleGetUrlParam()} />
    </>
  );
};

export default Header;
