import { useEffect, useLayoutEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HatGraduation } from "styled-icons/fluentui-system-filled";
import { City } from "styled-icons/boxicons-solid";
import IdahoStateSeal from "../../styles/images/idahoStateSeal.webp";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import { AboutSection, InfoBubble, ReportSection } from "../../components";
import { DollarSignIcon } from "../../styles/icons";
import {
  NAV_TAGS,
  EDUCATION_EMBEDDED_REPORTS,
  API_CONSTANTS,
  ABOUT_SECTION_CONSTANTS,
} from "../../types/constants";
import "../landingPages.scss";

const EducationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const exploreDataRef = useRef<HTMLDivElement>(null);
  const yearOverYearRef = useRef<HTMLDivElement>(null);
  const educationSalaryCostsRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (state) {
      if (state.tag === NAV_TAGS.EXPLORE_EDUCATION_DATA) {
        exploreDataRef.current?.scrollIntoView({ behavior: "smooth" });
      } else if (state.tag === NAV_TAGS.EDUCATION_YEAR_OVER_YEAR) {
        yearOverYearRef.current?.scrollIntoView({ behavior: "smooth" });
      } else if (state.tag === NAV_TAGS.EDUCATION_SALARY_COSTS) {
        educationSalaryCostsRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      navigate(location.pathname, { replace: true });
    }
  }, [state]);

  return (
    <div className="landing-page-container landing-page-picture-background">
      <header>
        <Header />
      </header>
      <main>
        <div className="landing-page-hero-container">
          <span className="landing-page-hero-data">
            <div className="landing-page-hero-info">
              <img
                className="landing-page-idaho-state-seal"
                src={IdahoStateSeal}
                alt="Idaho State Seal"
              />
              <span className="landing-page-explore-info-text">
                <h2 className="landing-page-hero-header">Explore Education</h2>
                <h6 className="landing-page-hero-subheader">
                  Idaho has a multitude of education providers. Their roles in
                  educating the next generation is vital to the success of the
                  state.
                </h6>
              </span>
            </div>
            <div className="landing-page-info-bubbles">
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderStudents.svg`}
                Icon={HatGraduation}
                infoAmount={"314,442"}
                infoDetailText="Students"
              />
              <InfoBubble
                Icon={City}
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderProviders.svg`}
                infoAmount={"179"}
                infoDetailText="Providers"
              />
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderStateAppropriation.svg`}
                Icon={DollarSignIcon}
                infoAmount={"3.383"}
                infoDetailText="Billion"
              />
            </div>
          </span>
        </div>
        <span className="hero-separator" />
        <AboutSection
          imageUrl={API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-4.png"}
          imageAltText={ABOUT_SECTION_CONSTANTS.EDUCATION.IMG_ALT}
          title={ABOUT_SECTION_CONSTANTS.EDUCATION.TITLE}
          description={ABOUT_SECTION_CONSTANTS.EDUCATION.DESCRIPTION}
          dataInfo={ABOUT_SECTION_CONSTANTS.EDUCATION.DATA_INFO}
          ref={exploreDataRef}
        />
        <div className="landing-page-reports-container landing-page-background">
          <div className="app-padding">
            <h4 className="landing-page-high-level-header">
              EXPLORE HIGH LEVEL EDUCATION DATA
            </h4>
            <p className="body-large landing-page-high-level-subheader">
              Below you will find interactive reports for budgets, salaries, and
              more. See information next to reports for helpful descriptions and
              insights.
            </p>
          </div>
          <ReportSection
            ref={yearOverYearRef}
            scrollable
            title="Year Over Year Financials"
            description={
              "Idaho public schools are funded primarily from state general funds and are supplemented by state dedicated funds, federal funds, and local funds. State general and dedicated funds are distributed to public schools according to state statutes and should be reflected in their year-over-year revenue and spending. \n\nAll education providers submit their aggregated revenue and expenditure data to the State Board of Education annually. To learn more about the year-over-year education financial trends, dive into the interactive data to see the revenue and spending data for the various education providers. This data includes a checkbook-level analysis of incoming and outgoing funds. The information provided includes the revenue and expenditure trends over the last three (3) years. \n\nWith this checkbook analysis, you can compare revenue and expenditure trends from one education provider to another as well as yearly trends within the data to better understand the budgets within Idaho’s education system."
            }
            reportId={
              EDUCATION_EMBEDDED_REPORTS.EducationYearOverYearFinancials
                .reportId
            }
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-year-over-year-financials.png"
            altDownloadLink={
              "https://idaho-sco-central-registry-documents.s3-us-gov-west-1.amazonaws.com/PowerBi-Report-Documents/School+District+Aggregated+OpenGov+Consolidated.xlsx"
            }
          />
          <ReportSection
            ref={educationSalaryCostsRef}
            scrollable
            title="Education Salary"
            description="This report includes position and salary information for education provider employees in the state of Idaho. Explore the interactive salary data to learn more about what each education provider expends to pay its employees. "
            descriptionBold="The information provided includes the salary amounts for the 2022-2023 school year."
            reportId={EDUCATION_EMBEDDED_REPORTS.EducationSalaries.reportId}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-education-salary.png"
          />
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default EducationPage;
