import { useNavigate } from "react-router-dom";
import { API_CONSTANTS } from "../../../../types/constants";
import "./countyItem.scss";

type CountyItemProps = {
  id: number;
  name: string;
  onClickAction?: () => void;
};

const CountyItem = ({ id, name, onClickAction }: CountyItemProps) => {
  const navigate = useNavigate();

  return (
    <div
      className="county-item"
      onClick={() => {
        navigate(`/Counties/${id}`);
        onClickAction && onClickAction();
      }}
    >
        <img
          className="seal-image"
          src={
            API_CONSTANTS.imageBucket +
            "/county-seal-images/CountySeal" +
            id +
            ".png"
          }
          alt={`${name} County Seal`}
          loading="lazy"
        />
      <p>{name} County</p>
    </div>
  );
};

export default CountyItem;
