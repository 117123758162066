import { RefObject, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IdahoStateSealImage from "../../styles/images/idahoStateSeal.webp";
import {
  DollarBillIcon,
  QuickLinkIcon,
  IdahoIcon,
  DollarSignIcon,
} from "../../styles/icons";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import {
  AboutSection,
  LoadingBar,
  LoadingState,
  QuickLinksSection,
  ReportSection,
  StickyNav,
  TransparentSearchBar,
} from "../../components";
import {
  ABOUT_SECTION_CONSTANTS,
  API_CONSTANTS,
  EXTERNAL_SITE_LINKS,
  NAV_TAGS,
  STATE_URLS,
  URL_CONSTANTS,
} from "../../types/constants";
import StateBudgetProcess from "./StateLandingPageComponents/StateBudgetProcess";
import StateAdditionalReports from "./StateLandingPageComponents/StateAdditionalReports";
import StateAgenciesSearch from "../stateAgencyPage/stateAgencyComponents/StateAgenciesSearch";
import { STATE_ADDITIONAL_REPORTS } from "./stateConstants";
import { ReportsObject } from "../../types/interfaces/commonInterfaces";
import { getOpenGovReportsByPage, openGovPages } from "../../services/openGovReportService";
import "../landingPages.scss";
import "./stateLandingPage.scss";

const StateLandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.Idle
  );
  const [openGovReports, setOpenGovReports] = useState<ReportsObject>()

  const quickLinksRef = useRef<HTMLDivElement>(null);
  const stateAboutRef = useRef<HTMLDivElement>(null);
  const stateBudgetProcessRef = useRef<HTMLDivElement>(null);
  const stateBudgetRef = useRef<HTMLDivElement>(null);
  const stateFinancialDataRef = useRef<HTMLDivElement>(null);
  const stateAgenciesRef = useRef<HTMLDivElement>(null);
  const budgetToActualsRef = useRef<HTMLDivElement>(null);
  const stateTransactionsRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleLoad = async () => {
      const data = await getOpenGovReportsByPage(openGovPages.StateLanding)
      const reportsObject = data.reduce((acc: any, current: any) => {
        acc[current.Report_Name.trim()] = current;
        return acc;
      }, {});
      setOpenGovReports(reportsObject)
    }
    handleLoad()
  }, [])



  useEffect(() => {
    if (state && openGovReports) {
      if (state.tag === NAV_TAGS.STATE_BUDGET) {
        handleScroll(stateBudgetRef);
      } else if (state.tag === NAV_TAGS.STATE_BUDGET_TO_ACTUAL) {
        handleScroll(budgetToActualsRef);
      } else if (state.tag === NAV_TAGS.STATE_TRANSACTIONS) {
        handleScroll(stateTransactionsRef);
      } else if (state.tag === NAV_TAGS.STATE_ABOUT) {
        handleScroll(stateAboutRef);
      } else if (state.tag === NAV_TAGS.STATE_AGENCIES) {
        handleScroll(stateAgenciesRef);
      }
      navigate(location.pathname, { replace: true });
    }
  }, [state, openGovReports]);

  const handleScroll = (refToScroll: RefObject<HTMLDivElement>) => {
      const stickyNavHeight =
        document.querySelector(".sticky-nav")?.clientHeight ?? 0;
      if (refToScroll?.current) {
        const refRect = refToScroll.current.getBoundingClientRect();
        const absoluteRefTop = refRect.top + window.scrollY;
        const scrollPosition = absoluteRefTop - stickyNavHeight;
        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
  };

  return (
    <div className="county-page-container ">
      {
        <LoadingBar
          loadingState={loadingState}
          setLoadingState={setLoadingState}
        />
      }
      <header>
        <Header />
      </header>
      <main>
        <div className="landing-page-hero-container ">
          <img
            src="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/ui-images/sco-ui-state-capital-landing.png"
            className="state-landing-page-hero-image"
          />
          <span className="landing-page-hero-data state glass">
            <div className="landing-page-hero-info">
              <img
                className="landing-page-idaho-state-seal"
                src={IdahoStateSealImage}
                alt="Idaho State Seal"
              />
              <span className="landing-page-explore-info-text">
                <h2 className="landing-page-hero-header text-white">
                  Explore State Data
                </h2>
                <h6 className="landing-page-hero-subheader text-white">
                  Explore Idaho's 75 State agencies, each providing unique and
                  vital services to the citizens of Idaho.
                </h6>
              </span>
            </div>
            {/* <div className="landing-page-info-bubbles">
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderStateBudget.svg`}
                infoDetailText={"Billion"}
                Icon={CountyIconBubbles}
                infoAmount={"832"}
                whiteBubbleTitleColor={true}
              />
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderStateEmployees.svg`}
                infoDetailText={"People"}
                Icon={CountyIconBubbles}
                infoAmount={"38"}
                whiteBubbleTitleColor={true}
              />
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderStateAgencies.svg`}
                Icon={CountyIconBubbles}
                infoAmount={"49"}
                infoDetailText={"Agencies"}
                whiteBubbleTitleColor={true}
              />
            </div> */}
          </span>
        </div>
        <StickyNav
          sections={[
            {
              icon: <QuickLinkIcon />,
              text: "Quicklinks",
              ref: quickLinksRef,
            },
            {
              icon: <DollarSignIcon />,
              text: "State Budget Process",
              ref: stateBudgetProcessRef,
            },
            {
              icon: <DollarBillIcon />,
              text: "State Financial Data",
              ref: stateFinancialDataRef,
            },
            {
              icon: <IdahoIcon />,
              text: "State Agencies",
              ref: stateAgenciesRef,
            },
          ]}
        />
        <AboutSection
          imageUrl={
            API_CONSTANTS.imageBucket + "/ui-images/sco-ui-downtown.png"
          }
          imageAltText={ABOUT_SECTION_CONSTANTS.STATE.IMG_ALT}
          title={ABOUT_SECTION_CONSTANTS.STATE.TITLE}
          description={ABOUT_SECTION_CONSTANTS.STATE.DESCRIPTION}
          ref={stateAboutRef}
        />
        <QuickLinksSection
          ref={quickLinksRef}
          links={[
            {
              imgSrc: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-townhall-idaho.png`,
              imgAlt: "Town hall microphone",
              title: "Townhall Idaho",
              pageUrl: EXTERNAL_SITE_LINKS.TOWN_HALL_IDAHO,
            },
            {
              imgSrc: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-public-records.png`,
              imgAlt: "File Cabinet with public records",
              title: "Public Records",
              pageUrl: URL_CONSTANTS.PUBLIC_RECORD_REQUEST,
            },
            {
              imgSrc: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-state-agreements.png`,
              imgAlt: "",
              title: "State Agreements and Contracts",
              pageUrl: EXTERNAL_SITE_LINKS.MOU,
            },
            {
              imgSrc: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-image-9.png`,
              imgAlt: "",
              title: "Vendor Payments",
              pageUrl: URL_CONSTANTS.VENDOR_PAYMENTS,
            },
          ]}
        />
        <StateBudgetProcess ref={stateBudgetProcessRef} />
        <div
          className="landing-page-reports-container landing-page-background"
          ref={stateFinancialDataRef}
        >
          <div className="app-padding" ref={stateFinancialDataRef}>
            <h4 className="landing-page-high-level-header">
              State Financial Data
            </h4>
            <p className="landing-page-high-level-subheader body-large">
              Below you will be able to dive into statewide financial data
              through various views we have provided.
            </p>
          </div>
          <div >
            {
              openGovReports &&
              <>
                <ReportSection
                  scrollable
                  title="Budget"
                  ref={stateBudgetRef}
                  description={
                    "Inspect Idaho's budget allocations within this report, unveiling the state's financial priorities, and allocating funding for areas like education, healthcare, and public safety. Delve in to dissect detailed transactions and track agency spending. From environmental protection to infrastructure, discover the impact of each allocation."

                  }
                  reportId=""
                  openGovReport={openGovReports?.Budget?.Embed_Link ?? ""}
                  newTabLink={openGovReports?.Budget?.URLs ?? ""}
                />


                <ReportSection
                  scrollable
                  title="Actuals"
                  description={
                    "The Monthly Actuals report allows you to track state expenditures against the established budget, offering insights similar to managing personal finances. The incremental data unveils month-to-month expenditure trends, breaking down the various expenditures of the State of Idaho."
                  }
                  reportId=""
                  ref={budgetToActualsRef}
                  openGovReport={openGovReports?.Actuals?.Embed_Link ?? ""}
                  newTabLink={openGovReports?.Actuals?.URLs ?? ""}
                  noPaddingTop
                />
                <ReportSection
                  scrollable
                  title="State Revenues"
                  description={
                    "Dive into the Revenue report which allows you to track State revenues that make up the budget. The incremental data unveils month-to-month revenue trends breaking down the various revenue streams for the state of Idaho."
                  }
                  reportId=""
                  openGovReport={openGovReports?.["State Revenues"]?.Embed_Link ?? ""}
                  newTabLink={openGovReports?.["State Revenues"]?.URLs ?? ""}
                />
                <ReportSection
                  scrollable
                  title="Employee Pay Rates"
                  description={
                    "Navigate the intricacies of Idaho's Employee Pay Rate Report for an insightful view into state workforce compensation. This report offers a comprehensive breakdown of employee pay rates. Important: Be aware that employees holding multiple positions will have each role they fulfill listed and may appear more than once."
                  }
                  reportId=""
                  openGovReport={openGovReports?.['Employee Pay Rates']?.Embed_Link ?? ""}
                  newTabLink={openGovReports?.['Employee Pay Rates']?.URLs ?? ""}
                />
                <ReportSection
                  scrollable
                  title="Top Vendor - Total Spent"
                  description={
                    "The Vendor Report provides overviews of statewide vendor transactions but also enables users to filter and pinpoint which vendors the state as whole or individual agencies spent the most money with. Understand the intricacies of purchases, services, and financial exchanges, empowering stakeholders with a granular understanding of how public funds are utilized."
                  }
                  reportId=""
                  openGovReport={openGovReports?.['Top Vendors Total Spent']?.Embed_Link ?? ""}
                  newTabLink={openGovReports?.['Top Vendors Total Spent']?.URLs ?? ""}
                />
                <ReportSection
                  scrollable
                  title="Top Vendor - Most Used"
                  description={
                    "The Top Vendor Report offers a comprehensive vendor transaction overview of the foremost vendors utilized by the state. The report delves into the intricacies of purchases, services, and financial exchanges, providing a granular understanding of how public funds are spent."
                  }
                  reportId=""
                  openGovReport={openGovReports?.['Top Vendors Most Used']?.Embed_Link ?? ""}
                  newTabLink={openGovReports?.['Top Vendors Most Used']?.URLs ?? ""}
                />
              </>
            }
          </div>

        </div>
        <div className="landing-page-reports-container landing-page-background-blue">
          <div className="app-padding">
            <h4 className="landing-page-high-level-header">
              Coronavirus Relief Funding
            </h4>
            <div className="covid-description-container">
              <TransparentSearchBar version={3} top="-1rem" />
              <p className="landing-page-high-level-subheader body-large">
                Discover the impact of the Coronavirus Relief Funding (CRF)
                allocated to combat the Coronavirus in Idaho. This portal
                provides a line item transaction insight into the $1.25 billion
                allocated to the state.
              </p>
            </div>
            <div className="mobile-container">
              <TransparentSearchBar version={3} mobile />
            </div>
          </div>
          <ReportSection
            scrollable
            blue
            title="CRF Expenditure Detail"
            description={`The Expenditure Overview dataset includes summary data from all Idaho Rebounds initiatives. For transaction level details visit: `}
            descriptionLink={{
              title: "idahorebounds.opengov.com/data/",
              link: "https://idahorebounds.opengov.com/data/#/48245/query=8BEEF8BBD7B67BB334183529A2132EC3&embed=n",
            }}
            reportId=""
            openGovReport={STATE_URLS.COVID_REPORT}
          />
        </div>
        <div ref={stateTransactionsRef}>
          <StateAdditionalReports reports={openGovReports} />
        </div>
        <div ref={stateAgenciesRef}>
          <StateAgenciesSearch />
        </div>
        <br />
        <br />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default StateLandingPage;
