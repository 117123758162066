import React, { useEffect, useRef, useState } from "react";

interface LazyLoadIframeProps {
  src: string;
  fallbackContent: React.ReactNode;
  iframeProps?: React.IframeHTMLAttributes<HTMLIFrameElement>;
  rootMargin?: string;
  threshold?: number | number[];
}

const LazyLoadIframe: React.FC<LazyLoadIframeProps> = ({
  src,
  fallbackContent,
  iframeProps,
  rootMargin = "0px",
  threshold = 0,
}) => {
  const [shouldLoadIframe, setShouldLoadIframe] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!("IntersectionObserver" in window)) {
      setShouldLoadIframe(true);
      return;
    }

    let observer: IntersectionObserver;
    const currentRef = containerRef.current;

    if (currentRef) {
      observer = new IntersectionObserver(
        (entries, obs) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setShouldLoadIframe(true);
              obs.unobserve(entry.target);
            }
          });
        },
        {
          rootMargin,
          threshold,
        },
      );
      observer.observe(currentRef);
    }

    return () => {
      if (observer && currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [rootMargin, threshold]);

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      {!iframeLoaded && shouldLoadIframe && fallbackContent}
      {shouldLoadIframe && (
        <iframe
          {...iframeProps}
          src={src}
          onLoad={() => setTimeout(() => {
            setIframeLoaded(true);
          }, 500)}
          style={{
            position: iframeLoaded ? "static" : "absolute",
            top: 0,
            left: 0,
            zIndex: 2,
            border: "none",
            opacity: iframeLoaded ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
        />
      )}
    </div>
  );
};

export default LazyLoadIframe;
