import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IdahoStateSeal from "../../styles/images/idahoStateSeal.webp";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import {
  AboutSection,
  ReportSection,
  TransparentSearchBar,
} from "../../components";
import {
  EDUCATION_EMBEDDED_REPORTS,
  API_CONSTANTS,
  ABOUT_SECTION_CONSTANTS,
  VENDORS_URLS,
  NAV_TAGS,
} from "../../types/constants";
import { getOpenGovReportsByPage, openGovPages } from "../../services/openGovReportService";
import { ReportsObject } from "../../types/interfaces/commonInterfaces";
import "../landingPages.scss";

const VendorSalaryLandingPage = () => {
  const [openGovReports, setOpenGovReports] = useState<ReportsObject>()

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const topVendorTotalSpentRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  useEffect(() => {
    if (state) {
      if (state.tag === NAV_TAGS.TOP_VENDOR_TOTAL_SPENT) {
        topVendorTotalSpentRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      navigate(location.pathname, { replace: true });
    }
  }, [state]);

  useEffect(() => {
    const handleLoad = async() => {
     const data = await getOpenGovReportsByPage(openGovPages.Vendor)
     const reportsObject = data.reduce((acc: any, current:any) => {
      acc[current.Report_Name.trim()] = current;
      return acc;
    }, {});
     setOpenGovReports(reportsObject)
    }
    handleLoad()
  }, [])

  return (
    <div className="landing-page-container landing-page-picture-background">
      <header>
        <Header />
      </header>
      <main>
        <div className="landing-page-hero-container">
          <TransparentSearchBar version={1} top="-3rem" />
          <span className="landing-page-hero-data">
            <div className="landing-page-hero-info">
              <img
                className="landing-page-idaho-state-seal"
                src={IdahoStateSeal}
                alt="Idaho State Seal"
              />
              <span className="landing-page-explore-info-text">
                <h2 className="landing-page-hero-header">Vendor Payments</h2>
                <h6 className="landing-page-hero-subheader">
                  Explore every transactional payment made to vendors working
                  with the State of Idaho. Transaction-level detail accounting
                  for every dollar spent.
                </h6>
              </span>
            </div>
            <div className="mobile-container">
                <TransparentSearchBar version={1} mobile />
              </div>
            {/* <div className="landing-page-info-bubbles"> */}

            {/* <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderPaidLastYear.svg`}
                Icon={HatGraduation}
                infoAmount={"311,594"}
                infoDetailText="Students"
              />
              <InfoBubble
                Icon={City}
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderTopVendor.svg`}
                infoAmount={"186"}
                infoDetailText="Providers"
              />
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderTotalVendors.svg`}
                Icon={DollarSignIcon}
                infoAmount={"2.363"}
                infoDetailText="Billion"
              /> */}
            {/* </div> */}
          </span>
        </div>
        <span className="hero-separator" />
        <AboutSection
          imageUrl={API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-9.png"}
          imageAltText={ABOUT_SECTION_CONSTANTS.VENDOR_PAYMENTS.IMG_ALT}
          title={ABOUT_SECTION_CONSTANTS.VENDOR_PAYMENTS.TITLE}
          description={ABOUT_SECTION_CONSTANTS.VENDOR_PAYMENTS.DESCRIPTION}
          dataInfo={ABOUT_SECTION_CONSTANTS.VENDOR_PAYMENTS.DATA_INFO}
        />

        <div className="landing-page-reports-container landing-page-background">
          <div className="app-padding">
            <h4 className="landing-page-high-level-header">Explore Vendors</h4>
            <p className="body-large landing-page-high-level-subheader">
              Below you will find information surrounding the vendors that
              service and partner with state agencies in order to provide goods
              or services.
            </p>
          </div>
          <ReportSection
            scrollable
            title="Top Vendor - Total Spent"
            description={
              "The Vendor Report provides overviews of statewide vendor transactions but also enables users to filter and pinpoint which vendors the state as a whole or individual agencies spent the most money with. Understand the intricacies of purchases, services, and financial exchanges, with a granular understanding of how public funds are utilized. "
            }
            reportId={""}
            openGovReport={openGovReports?.['Top Vendors - Total Spent']?.Embed_Link ?? ""}
            newTabLink={openGovReports?.['Top Vendors - Total Spent']?.URLs ?? ""}
            ref={topVendorTotalSpentRef}
          />
          <ReportSection
            scrollable
            title="Top Vendor - Most Used"
            description="The Top Vendor Report not only offers comprehensive statewide vendor transaction overviews but also enables users to filter and identify the foremost vendors utilized by the state or within specific agencies. Understand the intricacies of purchases, services, and financial exchanges, providing a granular understanding of how public funds are strategically employed."
            reportId={EDUCATION_EMBEDDED_REPORTS.EducationSalaries.reportId}
            openGovReport={openGovReports?.['Top Vendors - Most Used']?.Embed_Link ?? ""}
            newTabLink={openGovReports?.['Top Vendors - Most Used']?.URLs ?? ""}
          />
          <ReportSection
            scrollable
            title="Vendor Transaction"
            description="The Vendor Transaction Report allows you to uncover the transaction-level details of financial interactions with our vendors, where every penny spent is accounted for.  This detailed report provides a transparent overview of transactions between the state agencies and various vendors."
            reportId={EDUCATION_EMBEDDED_REPORTS.EducationSalaries.reportId}
            openGovReport={openGovReports?.['Transaction']?.Embed_Link ?? ""}
            newTabLink={openGovReports?.['Transaction']?.URLs ?? ""}
          />
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default VendorSalaryLandingPage;
